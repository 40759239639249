import {Component, OnInit, ViewChild} from '@angular/core';
import {
  GroupService,
  GroupTreeViewDto,
  IdentityProviderConnectionService,
  IdentityProviderConnectionViewDto,
  UserTreeViewDto,
  IdentityProviderConnectionUsersDto,
  FieldErrorDto,
} from '@lancrypt/lc-portal-fe-cmp-typescript/build/out-tsc';
import {ApiClientFactoryService} from '../../../../../services/apiclient-factory.service';
import {JwtHelperService} from '../../../../../services/helper/jwt-helper.service';
import {ToastService} from '../../../../../services/toaster.service';
import {TranslateService} from '@ngx-translate/core';
import {GroupTreeViewIconDto, SubFolderType} from '../../../../../dtos/lancrypt/GroupTreeViewIconDto';
import {GroupTreeService, GroupTreeTrait} from '../../../../../services/group-tree.service';
import {ActivatedRoute, Params, Router} from '@angular/router';
import {DualOptionDialog} from '../../../../../shared/components/dual-option-dialog/dual-option-dialog.component';
import {MatTree} from '@angular/material/tree';
import {MatDialog} from '@angular/material/dialog';
import {MatCheckboxChange} from '@angular/material/checkbox';
import {SelectionModel} from '@angular/cdk/collections';
import {BusyService} from '../../../../../shared/components/busy-service/busy.service';
import {debounceTime, distinctUntilChanged, Subject} from 'rxjs';
import {DEFAULT_DEBOUNCE_TIME} from '../../../../../shared/lancrypt.constants';

export type SelectionNode = [SubFolderType, string];

@Component({
  selector: 'app-connection-import',
  templateUrl: './connection-import.component.html',
  styleUrls: ['./connection-import.component.scss'],
})
export class ConnectionImportComponent implements OnInit {
  @ViewChild('localTree') localTree!: MatTree<GroupTreeViewIconDto>;
  @ViewChild('connectionTree') connectionTree!: MatTree<GroupTreeViewIconDto>;

  private userPageSize = 50;

  private tenantId = '';
  private connectionId = '';
  private skipToken: string | undefined = undefined;
  private loadMoreUsersTriggered = false;

  private groupApiClient: GroupService;
  private connectionService: IdentityProviderConnectionService;

  hasLoadingError = false;
  loadingErrorMessage = '';
  isLoadingUsers = false;

  directoryObjectFilterValue = '';
  directoryObjectFilter = new Subject<string>();
  directoryObjectFilterTrigger = this.directoryObjectFilter.pipe(
    debounceTime(DEFAULT_DEBOUNCE_TIME),
    distinctUntilChanged()
  );

  localData: GroupTreeViewIconDto[] = [];
  connectionData: GroupTreeViewIconDto[] = [];
  connectionGroups: GroupTreeViewIconDto[] = [];

  getChildren = (node: GroupTreeViewIconDto) => node.children ?? [];
  hasChild = (_: number, node: GroupTreeViewIconDto) => !!node.children && node.children.length > 0;
  trackBy = (_: number, node: GroupTreeViewIconDto) => this.expansionKey(node);
  expansionKey = (node: GroupTreeViewIconDto) => node.treeId;

  // The actual selection to be used for displaying in the UI and importing in the backend
  checklistSelection = new SelectionModel<SelectionNode>(
    true,
    undefined,
    true,
    (a: SelectionNode, b: SelectionNode) => a[1] === b[1]
  );

  // Keep track of manually deselected nodes (to override setting of selction check box after reloading user list with different filters)
  manualDeselection = new SelectionModel<SelectionNode>(
    true,
    undefined,
    true,
    (a: SelectionNode, b: SelectionNode) => a[1] === b[1]
  );

  isSyncInProgress = false;

  subtypes = SubFolderType;

  constructor(
    apiClientFactory: ApiClientFactoryService,
    private jwtHelperService: JwtHelperService,
    private toastService: ToastService,
    private translateService: TranslateService,
    private groupTreeService: GroupTreeService,
    private route: ActivatedRoute,
    private dialog: MatDialog,
    private router: Router,
    private busyService: BusyService
  ) {
    this.groupApiClient = apiClientFactory.getGroupService();
    this.connectionService = apiClientFactory.getIdentityProviderConnectionService();
  }

  ngOnInit(): void {
    this.jwtHelperService.getTenantIdFromToken().then(tenantId => {
      this.tenantId = tenantId;
      this.route.params.subscribe((params: Params) => {
        this.connectionId = params['connectionId'];

        if (!this.connectionId) {
          this.connectionService
            .getConnectionList(this.tenantId)
            .subscribe((connections: IdentityProviderConnectionViewDto[]) => {
              if (connections && connections.length > 0) {
                this.router.navigate(['lancrypt', 'connections', 'import', connections[0].id]);
              } else {
                this.router.navigate(['lancrypt', 'connections', 'create-connection']);
              }
            });
        } else {
          this.loadLocalView();
          this.loadConnectionView();
        }
      });
    });

    this.directoryObjectFilterTrigger.subscribe(currentValue => {
      // Reset skip token (filter value change indicates a completely new users search request)
      this.skipToken = undefined;

      // If filter value has been manually set to be empty again set this.loadMoreUsersTriggered to true
      // so that the 'Users' node will get expanded (UX feels more natural).
      this.loadMoreUsersTriggered = !!currentValue;

      // Make search case insensitive
      this.directoryObjectFilterValue = currentValue.trim().toLowerCase();

      // Remove old users from tree during loading of the new list of (filtered) users
      const usersTreeDto = this.getUsersTree();
      usersTreeDto.children = [];

      // Filter groups
      this.connectionData = this.filterGroupsRecursive(this.directoryObjectFilterValue, this.connectionGroups);

      this.connectionTree.dataSource = [];
      this.connectionTree.dataSource = this.connectionData;

      // Expand all nodes if a filter value is set
      if (this.directoryObjectFilterValue) {
        this.connectionTree.expandAll();
      } else {
        this.connectionTree.expand(this.connectionData[0]);
      }

      // Load users matching with filter
      this.loadUsers();
    });
  }

  public importConnection() {
    this.dialog
      .open(DualOptionDialog, {
        width: '550px',
        data: {
          title: this.translateService.instant('connectionImport.startImport'),
          description: this.translateService.instant('connectionImport.startImportText'),
          positiveTitle: this.translateService.instant('common.confirm'),
          negativeTitle: this.translateService.instant('common.cancel'),
        },
      })
      .afterClosed()
      .subscribe(result => {
        if (!result) {
          return;
        }

        // Check if sync all is selected (but only if tree is not filtered)
        const syncAll =
          this.checklistSelection.isSelected([SubFolderType.Group, this.connectionData[0].id]) &&
          !this.directoryObjectFilterValue;
        let userIds: string[] = [];
        let groupIds: string[] = [];
        if (!syncAll) {
          userIds = this.checklistSelection.selected
            .filter(node => node[0] === SubFolderType.User)
            .map(node => node[1]);

          groupIds = this.getDescendants(this.connectionGroups[0])
            .filter(
              node =>
                node.subType === SubFolderType.Group &&
                !node.root &&
                (this.checklistSelection.isSelected([SubFolderType.Group, node.id]) ||
                  this.descendantsPartiallySelected(node))
            )
            .map(node => node.id);
        }
        const syncDto = {
          syncAll: syncAll,
          userIds: userIds,
          groupIds: groupIds,
        };
        this.connectionService.startSync(syncDto, this.tenantId, this.connectionId).subscribe({
          next: async (_: any) => {
            this.toastService.showSuccess(
              this.translateService.instant('common.success'),
              this.translateService.instant('connectionImport.startImportSuccess')
            );
            this.router.navigate(['lancrypt', 'connections']);
          },
          error: async (_: any) => {
            this.toastService.showError(
              this.translateService.instant('common.error'),
              this.translateService.instant('connectionImport.error.startingImportFailed')
            );
          },
        });
      });
  }

  public allowImport(): boolean {
    return !this.isSyncInProgress && !this.busyService.isBusy();
  }

  public loadMoreUsers() {
    if (!this.isLoadingUsers && this.skipToken) {
      this.loadMoreUsersTriggered = true;
      this.loadUsers();
    }
  }

  public applyFilter(event: Event) {
    this.directoryObjectFilter.next((event.target as HTMLInputElement).value);
  }

  onCheck(change: MatCheckboxChange, node: GroupTreeViewIconDto) {
    if (change.checked) {
      this.checklistSelection.select([node.subType, node.id]);
      this.manualDeselection.deselect([node.subType, node.id]);
    } else {
      this.checklistSelection.deselect([node.subType, node.id]);
      this.manualDeselection.select([node.subType, node.id]);
    }

    if (!this.hasChild(0, node)) {
      return;
    }

    const descendants = this.getDescendants(node);
    if (this.checklistSelection.isSelected([node.subType, node.id])) {
      this.checklistSelection.select(...descendants.map<SelectionNode>(d => [d.subType, d.id]));
      this.manualDeselection.deselect(...descendants.map<SelectionNode>(d => [d.subType, d.id]));
    } else {
      this.checklistSelection.deselect(...descendants.map<SelectionNode>(d => [d.subType, d.id]));
      this.manualDeselection.select(...descendants.map<SelectionNode>(d => [d.subType, d.id]));
    }
  }

  descendantsAllSelected(node: GroupTreeViewIconDto): boolean {
    const descendants = this.getDescendants(node);

    const allSelected = descendants.every(child => this.checklistSelection.isSelected([child.subType, child.id]));
    if (allSelected && descendants.length > 0) {
      this.checklistSelection.select([node.subType, node.id]);
      this.manualDeselection.deselect([node.subType, node.id]);
      return true;
    } else {
      this.checklistSelection.deselect([node.subType, node.id]);
      this.manualDeselection.select([node.subType, node.id]);
      return false;
    }
  }

  descendantsPartiallySelected(node: GroupTreeViewIconDto): boolean {
    const descendants = this.getDescendants(node);
    const result = descendants.some(child => this.checklistSelection.isSelected([child.subType, child.id]));
    return result && !this.descendantsAllSelected(node);
  }

  private getDescendants(node: GroupTreeViewIconDto): GroupTreeViewIconDto[] {
    const children = this.getChildren(node);
    return children.concat(children.flatMap(c => this.getDescendants(c)));
  }

  private loadLocalView() {
    this.groupApiClient.getGroupTreeByTenantId(this.tenantId).subscribe({
      next: async (n: GroupTreeViewDto[]) => {
        if (!n) {
          this.toastService.showInfo(
            this.translateService.instant('common.info'),
            this.translateService.instant('infos.nogroupsFoundForTenant')
          );
          return;
        }

        this.localData = await this.groupTreeService.buildTreeView(n, GroupTreeTrait.None);

        // Expand all root nodes
        this.localData.forEach(node => {
          if (node.root) {
            this.localTree.expand(node);
          }
        });
      },
      error: async (_: any) => {
        this.toastService.showError(
          this.translateService.instant('common.error'),
          this.translateService.instant('errors.gettingGroups')
        );
      },
      complete: () => {},
    });
  }

  private loadConnectionView() {
    this.connectionService.getConnectionById(this.tenantId, this.connectionId).subscribe({
      next: (n: IdentityProviderConnectionViewDto) => {
        this.isSyncInProgress = n.activeImportJob;
        this.buildRemoteTree(n);
      },
      error: (_: any) => {
        this.toastService.showError(
          this.translateService.instant('common.error'),
          this.translateService.instant('connectionImport.error.gettingGroupsAndUsersFailed')
        );
      },
      complete: () => {},
    });
  }

  private buildRemoteTree(connection: IdentityProviderConnectionViewDto) {
    this.connectionService.loadGroups(this.tenantId, connection.id).subscribe({
      next: async (groups: GroupTreeViewDto[]) => {
        const root: GroupTreeViewDto = {
          id: connection.id,
          name: connection.name,
          description: '',
          root: true,
          children: groups,
          parents: [],
          treeHierarchyDirection: GroupTreeViewDto.TreeHierarchyDirectionEnum.DESCENDANTS,
          syncedGroup: true,
          identityProviderConnectionId: connection.id,
        };

        this.connectionGroups = await this.groupTreeService.buildTreeView([root], GroupTreeTrait.ShowUsers);
        this.connectionData = this.connectionGroups;

        this.connectionTree.expand(this.connectionData[0]);

        // Select already synced groups
        this.selectSyncedGroupsRecursive(groups);

        this.connectionService.loadSyncedUserIds(this.tenantId, connection.id).subscribe({
          next: async (userIds: string[]) => {
            this.checklistSelection.select(...userIds.map<SelectionNode>(id => [SubFolderType.User, id]));

            this.skipToken = undefined;
            this.loadUsers();
          },
          error: async (e: any) => {
            this.toastService.showError(
              this.translateService.instant('common.error'),
              this.translateService.instant('errors.gettingUsers')
            );
            this.handleError(e);
          },
          complete: () => {},
        });
      },
      error: async (e: any) => {
        this.toastService.showError(
          this.translateService.instant('common.error'),
          this.translateService.instant('errors.gettingGroups')
        );
        this.handleError(e);
      },
      complete: () => {},
    });
  }

  private loadUsers() {
    this.isLoadingUsers = true;
    this.connectionService
      .loadUsers(
        this.tenantId,
        this.connectionId,
        this.userPageSize,
        encodeURIComponent(this.directoryObjectFilterValue),
        this.skipToken
      )
      .subscribe({
        next: async (dto: IdentityProviderConnectionUsersDto) => {
          this.skipToken = dto.skipToken;
          const users = dto.users;

          this.addUsersToUsersNode(users);
        },
        error: async (e: any) => {
          this.toastService.showError(
            this.translateService.instant('common.error'),
            this.translateService.instant('errors.gettingUsers')
          );
          this.handleError(e);
        },
        complete: () => {
          this.isLoadingUsers = false;
        },
      });
  }

  private addUsersToUsersNode(users: UserTreeViewDto[]) {
    const userAsTreeNodes: GroupTreeViewIconDto[] = users.map(user => {
      // Select already synced users (if not already manually deselected)
      if (user.syncedUser && !this.manualDeselection.isSelected([SubFolderType.User, user.id])) {
        this.checklistSelection.select([SubFolderType.User, user.id]);
      }

      return {
        id: user.id,
        treeId: crypto.randomUUID(),
        name: user.displayName,
        icon: 'account_circle',
        subType: SubFolderType.User,
        groupName: '',
        children: [],
        parents: [],
        description: '',
        userAmount: 0,
        treeHierarchyDirection: GroupTreeViewDto.TreeHierarchyDirectionEnum.DESCENDANTS,
        root: false,
        syncedGroup: user.syncedUser,
        identityProviderConnectionId: '',
      };
    });

    const usersTreeDto = this.getUsersTree();

    // Append users if additional users have been loaded
    if (this.loadMoreUsersTriggered === true) {
      usersTreeDto.children = usersTreeDto.children.concat(...userAsTreeNodes);
    } else {
      usersTreeDto.children = userAsTreeNodes;
    }

    // Select all newly loaded users, when sync all was selected
    if (this.checklistSelection.isSelected([SubFolderType.Group, this.connectionData[0].id])) {
      this.checklistSelection.select(...userAsTreeNodes.map<SelectionNode>(n => [n.subType, n.id]));
      this.manualDeselection.deselect(...userAsTreeNodes.map<SelectionNode>(n => [n.subType, n.id]));
    }

    // Update tree data source for rendering updates
    this.connectionTree.dataSource = [];
    this.connectionTree.dataSource = this.connectionData;

    // Expand root node
    this.connectionTree.expand(this.connectionData[0]);

    // Expand users node if more users have been loaded
    if (this.loadMoreUsersTriggered) {
      this.connectionTree.expand(this.getUsersTree());
    }

    // When a filter value is available expand all nodes
    if (this.directoryObjectFilterValue) {
      this.connectionTree.expandAll();
    }
  }

  private getUsersTree(): GroupTreeViewIconDto {
    const rootNode = this.connectionData[0];
    return rootNode.children[rootNode.children.length - 1];
  }

  private handleError(e: any) {
    const errors = e.error?.errors as FieldErrorDto[];
    this.hasLoadingError = true;

    if (!errors || errors.length === 0 || errors[0].field.length === 0) {
      this.loadingErrorMessage = this.translateService.instant('connectionImport.error.gettingGroupsAndUsersFailed');
      return;
    }

    const reason = this.translateService.instant(`identityProviderConnection.error.${errors[0].field}`);
    this.loadingErrorMessage = this.translateService.instant(
      'connectionImport.error.gettingGroupsAndUsersFailedReason',
      {reason: reason}
    );
  }

  filterGroupsRecursive(filterText: string, array: GroupTreeViewIconDto[]): GroupTreeViewIconDto[] {
    // Local function to copy objects
    function copy(o: any) {
      return Object.assign({}, o);
    }

    // No filter string has been entered, just return original data
    if (!filterText) {
      return array;
    }

    // Filter case insensitive
    filterText = filterText.toLowerCase();

    return (
      array
        // Copy data so we do not mutate the original data
        .map(copy)
        // Filter the data
        .filter(function x(y) {
          // Include 'Users' node
          if (y.subType === SubFolderType.Users) {
            return true;
          }

          // Include matching node
          if (y.name.toLowerCase().includes(filterText) && !y.root) {
            return true;
          }

          // Otherwise filter children
          if (y.children) {
            y.children = y.children.map(copy).filter(x);
            return y.children.length > 0;
          }
          return false;
        })
    );
  }

  selectSyncedGroupsRecursive(groups: GroupTreeViewDto[]) {
    groups.forEach(group => {
      if (group.syncedGroup) {
        // Depth first: Go down one level
        if (group.children.length > 0) {
          this.selectSyncedGroupsRecursive(group.children);
        }

        // Select group if it is synced and has no children, or if it is synced and all children are synced.
        if (group.children.length === 0 || group.children.every(child => child.syncedGroup)) {
          this.checklistSelection.select([SubFolderType.Group, group.id]);
        }
      }
    });
  }
}
